import { render, staticRenderFns } from "./AlterPassword.vue?vue&type=template&id=16bc4c61&scoped=true&"
import script from "./AlterPassword.vue?vue&type=script&lang=js&"
export * from "./AlterPassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16bc4c61",
  null
  
)

export default component.exports