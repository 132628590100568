<template>
    <div class="index frame-content-wrap">
        <el-card>
            <el-form ref="form" :model="form" :rules="rules" label-width="80px" :show-message="true" style="max-width:500px;">
                <el-form-item label="原始密码" prop="oldpassword">
                    <el-input type="password" v-model="form.oldpassword" size="small"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newpassword">
                    <el-input type="password" v-model="form.newpassword" size="small"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirmpassword">
                    <el-input type="password" v-model="form.confirmpassword" size="small"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">立即创建</el-button>
                    <el-button @click="resetForm" size="small">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import http from "@/utils/http"
import verify from "@/utils/verify"
import commmon from "@/utils/common"

export default {
    name: 'AlterPassword',
    data(){
        return {
            form: {
                oldpassword: '',
                newpassword: '',
                confirmpassword: ''
            },
            rules: {
                oldpassword: [{required: true, message: '原始不能为空', trigger: 'blur'}],
                newpassword: [
                    {required: true, message: '新密码不能为空', trigger: 'blur'},
                    {validator: this.checkPassword, trigger: 'blur'}
                ],
                confirmpassword: [
                    {required: true, message: '确认密码不能为空', trigger: 'blur'},
                    {validator: this.checkConfirmPassword, trigger: 'blur'}
                ]
            },
            submitting: false
        }
    },
    methods: {
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post('/alter-password', this.form).then(req => {
                         this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('密码修改成功');
                            this.$refs.form.resetFields();
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        ...verify,
        ...commmon
    }
};
</script>

<style scoped>
</style>